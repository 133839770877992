import React from "react";
import axios from "axios";
import { useEffect, useState } from "react";


function ProductList () {
  const [Product, setProduct] = useState([]);
  const accessToken = localStorage.getItem("token");

  console.log('dklfhdwwd',accessToken)


// The empty dependency array ensures this effect runs once when the component mounts

useEffect(() => {
  const fetchData = async () => {
    try {
      const response = await axios.get(
        "https://inobackend-production.up.railway.app/api/v1/user/getAllProductList",
        {
          headers: {
            token: localStorage.getItem("token"),
          },
        }
      );
      const data = response.data;
      setProduct(data.result);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
console.log("mnssnfkqfnelgk")
  fetchData();
}, [accessToken]);
  return (
    <div>
      <h1>Product List</h1>
      <ul>
        {Product.map((product) => (
          <li key={product.id}>{product.userId}</li>
        ))}
      </ul>
    </div>
  );
}

export default ProductList;
