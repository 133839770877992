import React, { useEffect } from 'react';
import { useWallet } from '../Wallet/Context/WalletContext';

import { HiArrowNarrowRight } from "react-icons/hi";
import { NavLink } from "react-router-dom";
import coin from "../home/staticimages/coin.png"
import WalletTable from "./WalletTable";



function Walletbalance() {
  // 
  const { walletData } = useWallet();

// 
  useEffect(() => {
    const currentDate = new Date();
    console.log(`WalletComponent1 rendered at: ${currentDate}`);
  }, []); 

  return (
    <>

      <div className="flex flex-col">
        {/* child 1  */}
        <div className="1">
          <div className="flex flex-col gap-3 fade-in-left">
            {/* wallet  */}
            <div className="border-[1px] border-[#D2D2D2]  rounded-md w-[356.88px] h-[160px] drop-shadow-md">
              <div className="flex flex-col items-start ml-[41px]">
                <h6 className="font-all  lg:mt-[2rem] text-[13.5px] font-semibold">
                  Current Balance
                </h6>
               
                <h5 className="font-bold text-[19px]">
                  
                {walletData.balance.toLocaleString('en-IN')}
                   <span className='font-all text-sm'> inr</span>  </h5>
              </div>
              <div className="flex flex-row gap-3 justify-center mt-[1rem]">
                <NavLink to="/wallet/addmoney" > <button class=" border-[1px]    relative     hover:bg-[#CB2229] lg:w-[10rem] hover:text-white p-[3px] bg-[#d2d2d230]  drop-shadow-md border-1 font-all rounded-full transform hover:scale-105  duration-500 ease-in-out">
                  Add Money
                </button></NavLink>

<NavLink to="/wallet/withdraw"><button class="  border-[1px]     relative    hover:bg-[#CB2229] lg:w-[10rem] hover:text-white p-[3px] bg-[#d2d2d230] drop-shadow-md  border-1 font-all rounded-full transform hover:scale-105  duration-500 ease-in-out">
                  Withdraw Funds
                </button></NavLink>
                
              </div>
            </div>
            {/* reawrds  */}

            <div className="flex flex-row items-center">
              <div className="border-[1px]  border-[#D2D2D2]  rounded-md w-[356.88px] h-[160px] drop-shadow-md">
                <div className="flex flex-col items-start ml-[41px]">
                  <h6 className="font-all font-semibold mt-[2rem]">
                    Rewards ANd Execlusive offer
                  </h6>
                  <h5 className="font-bold text-[18px]">5015 points</h5>

                  <NavLink to="/wallet/walletrewards">  <button class=" flex justify-center items-center  border-[1px]   relative    hover:bg-[#CB2229] lg:w-[15rem] mt-4 hover:text-white p-[3px] bg-[#d2d2d230] drop-shadow-md  border-1 font-all rounded-full transform hover:scale-105  duration-500 ease-in-out ml-2">
                    View All  <HiArrowNarrowRight className="w-[37px] ml-1" />
                  </button></NavLink>

                </div>
              </div>

              <div className="image-coin fade-in-top">
                <img src={coin} className="h-[12rem] ml-[2rem] relative left-[51px]" alt="" />
              </div>



            </div>
          </div>
        </div>
        {/* child 2  */}
        <div className="2 lg:mt-[5rem] lg:relative lg:right-[3rem]">
          <WalletTable />
        </div>

      </div>
    </>
  );
}

export default Walletbalance;
