import React, { useEffect, useState } from 'react';
import { getAllProductCategories } from '../api/userRegestration/CollectionForm'; // Import the API function

function Response() {
  const [categories, setCategories] = useState([]);
  const accessToken = localStorage.getItem('token'); // Retrieve access token from local storage

  useEffect(() => {
 
    getAllProductCategories(accessToken)
      .then((result) => {
      
        setCategories(result);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, [accessToken]);

  return (
    <div>
      <h1>Product Categories</h1>
      {categories.length === 0 ? (
        <p>Loading...</p>
      ) : (
        <ul>
          {categories.map((category) => (
            <li key={category._id}>{category.name}</li>
          ))}
        </ul>
      )}
    </div>
  );
}

export default Response;
