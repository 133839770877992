import React, { useEffect, useState } from 'react'
import  {ListAllProjects} from '../../../../../../../api/userRegestration/CollectionForm'
import { useNavigate } from 'react-router-dom';
function ProjectListing() {
    const [categories, setCategories] = useState([]);
    const accessToken = localStorage.getItem("token");
    const navigate = useNavigate();
    const handlenavigate = (projecId) => {
    navigate(`/findProducts/listing/ProjectDetail/${projecId}`);
    
      };
    
      useEffect(() => {
        ListAllProjects(accessToken)
          .then((result) => {
            setCategories(result);
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
          });
      }, [accessToken]);
    
      const handlelistener = () => {
        navigate(`/`);
      };
  return (
  <>
    <div className="">
        <h1 className="font-all font-semibold text-center text-[24px] mt-4 mb-12">
          My Projects LIST
        </h1>

        <div className="flex flex-col">
          <div className="overflow-x-auto sm:mx-0.5 lg:mx-0.5">
            <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
              <div className="overflow-hidden">
                <table className="min-w-full">
                  <tbody>
                    {categories.map((category, index) => (
                      <tr
                        key={category._id}
                        className="bg-white border-b transition duration-300 ease-in-out hover:bg-gray-100"
                      >
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                          {index + 1}-
                        </td>
                        <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                          <img
                            className="h-[55px] w-[55px] rounded-md bg-yellow-100"
                            src={category.mediaUrl}
                            alt=""
                          />
                        </td>
                        <td className="text-sm  font-all font-semibold px-6 py-4 whitespace-nowrap">
                          {category.projectName}
                        </td>
                        <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                          <button
                            onClick={() => handlenavigate(category._id)}
                            className="bg-[#F8F8F8] h-[40px] w-[123px] shadow-lg rounded-full transform hover:scale-105 duration-500 ease-in-out hover:bg-[#CB2229] hover:text-white flex flex-row items-center justify-center gap-2"
                          >
                            View details
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <div className="--content--inquiries">
          <div className="button--action--reverse flex justify-center ">
            <button
              onClick={handlelistener}
              class="my-[2rem] bg-[#F8F8F8] shadow-lg p-[3px] w-[12rem] rounded-full transform hover:scale-105 duration-500 ease-in-out hover:bg-[#CB2229]  border-[1px] border-[#CB2229] hover:text-white flex flex-row items-center justify-center gap-2"
            >
              Go Back
            </button>
          </div>
        </div>
      </div>
  
  </>
  )
}

export default ProjectListing