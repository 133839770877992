import React, { useState } from "react";
import Logo from "../../assets/logo/Logo-Trans.png";
import image from "../../assets/logo/auth.png";
import {  useNavigate } from "react-router-dom";
import { verifyOTP } from "../../api/userRegestration/CollectionForm";
import { useParams } from "react-router-dom";


export default function VerifyPhoneOtp() {
  const { mobileNumber } = useParams();
  const [otp, setOtp] = useState("");

  const navigate = useNavigate();

  const handleOtpChange = (e) => {
    setOtp(e.target.value);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("data",mobileNumber ,otp)
    try {
      
      const result = await verifyOTP(mobileNumber, otp);
 
  console.log(result)

      navigate("/auth/registerUser");
    } catch (error) {
     
    }
  };

  return (
    <>
      <div className="xs:min-h-screen">
        <div className="lg:hidden  --logo flex justify-center xs:mt-[2rem]">
          <img
            src={Logo}
            alt=""
            className="lg:w-[134px] xs:h-[49px] bounce-in-top"
          />
        </div>
        <div className="flex flex-row justify-start">
        <div className="xs:hidden item-1 h-screen lg:w-[100vh] bg-[#cb22290f]">
            <div className="flex flex-col justify-center items-center mt-12 gap-[12pxs]">
              <img
                src={Logo}
                alt=""
                className="lg:w-[134pxl.] bounce-in-top  "
              />
            </div>
            <div className="text">
              <h1 className="text-center font-bold text-[35px] leading-[70px] text-[#738482] mt-9">
                Welcome to INO
              </h1>
              <p className="text-center  text-[#738482] font-all font-semibold ">
                Register now to gain access to your account{" "}
              </p>
            </div>
            <div className="image relative top-[14px]">
              <img
                src={image}
                className="mx-ato m-auto h-[21rem] image-coin"
                alt=""
              />
            </div>

            <div className="flex flex-row items-center gap-4 justify-center mt-[7rem]">
              <p className="text-[14px]">License Policy</p>
              <p className="border-x p-[8px]  text-[14px] border-black">
                Privacy Policy
              </p>
              <p className="text-[14px]">Terms and Conition</p>
            </div>
            <p className="text-[#738482] text-[13px] text-center">
              Copyrights © 2023 All Rights Reserved by{" "}
              <span className="text-[#CB2229]">INO</span>{" "}
            </p>
          </div>
          <div className="item-2 bg-[#cb22290f] xs:bg-white w-[50%] flex xs:w-[100%] xs">
            <div className="bg-white xs:bg-[#cb22290f] shadow-md w-[88%] rounded-md mt-[8rem] xs:mt-8 h-[552px] drop-c fade-in-right xs:mx-auto">
              <h1 className="text-all text-center font-bold xs:font-all xs:font-semibold text-[22px] relative top-[26px] xs:top-[-1px]">
                Verify Phone Number
              </h1>
              <h3 className="text-center text-[#000000] lg:mt-[36px]">
                Waiting to automatically detect an SMS sent to  <span className="font-bold "> "+{mobileNumber}" </span>
              </h3>
              <p className="text-[#8C8C8C] text-center my-4">
                enter otp 
              </p>
              <form onSubmit={handleSubmit}>
                <div className="input-flex-row flex flex-row gap-[22px] xs:gap-[6px] justify-center mt-[13px]">
                <input
            className="shadow appearance-none border rounded text-center py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            type="number"
            value={otp}
            onChange={handleOtpChange}
          />
                </div>
                <hr className="border border-[#738482] mt-[4.25rem] w-[50%] mx-auto m-auto" />
                <p className="text-[#8C8C8C] text-center mt-[18px]">
                  Enter 4-Digit Code
                </p>
                <p className="text-center text-[12px] mt-[50px] underline underline-offset-2">
                  Didn’t receive the code?
                </p>
                <div className="flex flex-col gap-3 mt-[40px]">
                  <button className="flex justify-center items-center mx-auto m-auto border-[1px] border-[#CB2229] text-black rounded-full font-all p-[2px] w-[15rem] transform hover:scale-105 duration-500 ease-in-out">
                    Send again in 0:04
                  </button>
                  <button className="flex justify-center items-center mx-auto m-auto border-[1px] border-[#CB2229] text-black rounded-full font-all p-[2px] w-[15rem] transform hover:scale-105 duration-500 ease-in-out">
                    Send again in 0:04
                  </button>

                  <button className="flex justify-center items-center mx-auto m-auto border-[1px] border-[#CB2229] text-black rounded-full font-all p-[2px] w-[15rem] transform hover:scale-105 duration-500 ease-in-out">
                    Get code by email instead
                  </button>
                  {/* onClick={handleNextClick} */}
                  {/* <Link to="/auth/registerUser"> */}
                  <button
                    type="submit"
                    // onClick={data}
                    className="flex justify-center items-center mx-auto m-auto border-[1px] bg-[#CB2229] text-white rounded-full font-all p-[2px] w-[15rem] transform hover:scale-105 duration-500 ease-in-out"
                  >
                    Next
                  </button>
                  {/* </Link> */}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
