import { useEffect, useState } from "react";
import {CreateBussinessCards} from "../../../../../../api/userRegestration/CollectionForm"

export default function CreateBussinessCard() {
  const accessToken = localStorage.getItem("token");
  const [formData, setFormData] = useState({
    name: "",
    identification: "",
    location: "",
    email: "",
    phoneNumber: "",
    companyName: "",
    position: "",
    website:"",
    socialLink:""

  });
  const handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;
    const newValue = type === "checkbox" ? checked : value;
    setFormData({ ...formData, [name]: newValue });
  }



  
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const produtdata = {
        name:formData.name,
        identification: formData.identification,
        location: formData.location ,
        email: formData.email,
        phoneNumber:formData.phoneNumber,
        companyName: formData.companyName,
        position: formData.position,
        website:formData.website,
        socialLink:formData.socialLink
      };
      const Product = await CreateBussinessCards(produtdata);
      console.log("post:", Product);
    } catch (error) {
      console.error("Error in :", error.message);
    }
  };
  return (
    <>
      <div className="bg-layout drop-shadow-md lg:w-[152vh] mb-12 lg:ml-[17rem] relative top-[1rem] swing-in-top-fwd">
        <div className="form">
          <form onSubmit={handleSubmit}>
            <div className="flex flex-row lg:gap-[4rem] mt-[3rem] mb-[2rem]">
              {/* 1 */}
              <div className="form-input flex flex-col  w-[47%]">
                <h1 className="font-all font-semibold text-center">
                  Enter Bussiness Card Details
                </h1>
                <div className="coolinput flex flex-col w-fit-content static max-w-240 lg:w-[541px]  ml-[2.1rem] ">
                  <label
                    htmlFor="input"
                    className=" text text-sm  text-black w-[5rem] rounded-md  bg-[white] relative top-2.5 ml-2 px-1 bg- w-fit-content text-center"
                  >
                    Full name
                  </label>
                  <input
                    type="text"
                    placeholder="Write here..."
                    name="name"
                    className="input px-2 py-3 text-sm border-[1px] border-[#738482] rounded-md focus:border-[1px] p-[10px]"
                    value={formData.name}
                  onChange={handleInputChange}
                  />
                </div>{" "}
                {/* 2  */}
                <div className="coolinput flex flex-col w-fit-content static max-w-240 lg:w-[541px]  ml-[2.1rem] ">
                  <label
                    htmlFor="input"
                    className=" text text-sm  text-black w-[12rem] rounded-md  bg-[white] relative top-2.5 ml-2 px-1 bg- w-fit-content text-center"
                  >
                    Occupation / Identification
                  </label>
                  <input
                    type="text"
                    placeholder="Write here..."
                    name="identification"
                    className="input px-2 py-3 text-sm border-[1px] border-[#738482] rounded-md focus:border-[1px] p-[10px]"
                    value={formData.identification}
                    onChange={handleInputChange}
                  />
                </div>{" "}
                {/* 3 */}
                <div className="coolinput flex flex-col w-fit-content static max-w-240 lg:w-[541px]  ml-[2.1rem] ">
                  <label
                    htmlFor="input"
                    className=" text text-sm  text-black w-[5rem] rounded-md  bg-[white] relative top-2.5 ml-2 px-1 bg- w-fit-content text-center"
                  >
                    Location
                  </label>
                  <input
                    type="text"
                    placeholder="Write here..."
                    name="location"
                    className="input px-2 py-3 text-sm border-[1px] border-[#738482] rounded-md focus:border-[1px] p-[10px]"
                    value={formData.location}
                    onChange={handleInputChange}
                  />
                </div>{" "}
                {/* 4  */}
                <div className="coolinput flex flex-col w-fit-content static max-w-240 lg:w-[541px]  ml-[2.1rem] ">
                  <label
                    htmlFor="input"
                    className=" text text-sm  text-black w-[5rem] rounded-md  bg-[white] relative top-2.5 ml-2 px-1 bg- w-fit-content text-center"
                  >
                    Email id
                  </label>
                  <input
                    type="text"
                    placeholder="Write here..."
                    name="email"
                    className="input px-2 py-3 text-sm border-[1px] border-[#738482] rounded-md focus:border-[1px] p-[10px]"
                    aria-autocomplete="list"
                    value={formData.email}
                    onChange={handleInputChange}
                  />
                </div>{" "}
                {/* 5  */}
                {/* text area  */}
                <div className="coolinput flex flex-col w-fit-content static max-w-240 lg:w-[541px]  ml-[2.1rem] ">
                  <label
                    htmlFor="input"
                    className=" text text-sm  text-black w-[8rem] rounded-md  bg-[white] relative top-2.5 ml-2 px-1 bg- w-fit-content text-center"
                  >
                    Mobile number
                  </label>
                  <input
                    type="text"
                    placeholder="Write here..."
                    name="phoneNumber"
                    className="input px-2 py-3 text-sm border-[1px] border-[#738482] rounded-md focus:border-[1px] p-[10px]"
                    aria-autocomplete="list"
                    value={formData.phoneNumber}
                    onChange={handleInputChange}
                  />
                </div>{" "}
              </div>

              {/* 2 */}

              <div className="flex flex-col  ">
                {/* 2-a  */}

                <div className="form lg:mt-8">
                  <div className="coolinput  flex flex-col w-fit-content static w-[25rem] lg:w-[541px]  ">
                    <label
                      htmlFor="input"
                      className=" text text-sm  text-black w-[7rem] rounded-md  bg-[white] relative top-2.5 ml-2 px-1 bg- w-fit-content text-center"
                    >
                      User Position
                    </label>
                    <input
                      type="text"
                      placeholder="Write here..."
                      name="position"
                      className="input px-2 py-3 text-sm border-[1px] w-[25rem] border-[#738482] rounded-md focus:border-[1px] p-[10px]"
                      value={formData.position}
                      onChange={handleInputChange}
                    />
                  </div>{" "}
                  <div className="coolinput  flex flex-col w-fit-content static w-[25rem] lg:w-[541px]  ">
                    <label
                      htmlFor="input"
                      className=" text text-sm   text-black w-[7rem] rounded-md  bg-[white] relative top-2.5 ml-2 px-1 bg- w-fit-content text-center"
                    >
                      Company name
                    </label>
                    <input
                      type="text"
                      placeholder="Write here..."
                      name="companyName"
                      className="input px-2 py-3 text-sm border-[1px] w-[25rem] border-[#738482] rounded-md focus:border-[1px] p-[10px]"
                      aria-autocomplete="list"
                      value={formData.companyName}
                      onChange={handleInputChange}
                    />
                  </div>{" "}
                  <div className="coolinput  flex flex-col w-fit-content static w-[25rem] lg:w-[541px]  ">
                    <label
                      htmlFor="input"
                      className=" text text-sm   text-black w-[8rem] rounded-md  bg-[white] relative top-2.5 ml-2 px-1 bg- w-fit-content text-center"
                    >
                       website
                    </label>
                    <input
                      type="text"
                      placeholder="Write here..."
                      name="website"
                      className="input px-2 py-3 text-sm border-[1px] w-[25rem] border-[#738482] rounded-md focus:border-[1px] p-[10px]"
                      aria-autocomplete="list"
                      value={formData.website}
                      onChange={handleInputChange}
                      
                    />
                  </div>{" "}
                  <div className="coolinput  flex flex-col w-fit-content static w-[25rem] lg:w-[541px]  ">
                    <label
                      htmlFor="input"
                      className=" text text-sm   text-black w-[8rem] rounded-md  bg-[white] relative top-2.5 ml-2 px-1 bg- w-fit-content text-center"
                    >
                      Social media link
                    </label>
                    <input
                      type="text"
                      placeholder="Write here..."
                      name="socialLink"
                      className="input px-2 py-3 text-sm border-[1px] w-[25rem] border-[#738482] rounded-md focus:border-[1px] p-[10px]"
                      aria-autocomplete="list"
                      value={formData.socialLink}
                      onChange={handleInputChange}
                    />
                  </div>{" "}
                </div>
                {/* 2-b  */}
                <div class="button-action flex flex-col items-center mt-[4rem] relative right-[3rem]">
                  <span>
                    <button type="sumit" class=" bg-[#CB2229] flex flex-row justify-center items-center gap-[21px] text-white rounded-full font-all p-[6px] lg:mt-[0.5rem] w-[18rem]   transform hover:scale-105 duration-500 ease-in-out relative">
                      save or create  <div role="status">
    <svg aria-hidden="true" class="inline w-4 h-4 mr-2 text-gray-200 animate-spin  fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
    </svg>
    <span class="sr-only">Loading...</span>
</div>
                    </button>
                  </span>
                  <a aria-current="page" class="active" href="/wallet">
                    <button class="mb-6 border-[1px] border-[#CB2229] text-black rounded-full font-all p-[6px] lg:mt-[0.5rem] w-[18rem]   transform hover:scale-105 duration-500 ease-in-out relative  ">
                      Go Back
                    </button>
                  </a>
                </div>
              </div>
              {/*  */}
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
